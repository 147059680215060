import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Stack } from '@mui/system';
import Container from '@mui/material/Container';
import { Grid, Divider, IconButton, Typography } from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';
import useLocalizedText from 'src/hooks/useLocalizedText';
import { useScreenWidth } from 'src/hooks/useScreenWidth';
import { useLayoutStyle } from 'src/hooks/useLayoutStyle';
import useTranslatedText from 'src/hooks/useTranslatedText';
import useImageTransformation from 'src/hooks/useImageTransformation';

import { colors } from 'src/utils/colors';

import { getSpecialSaleState } from 'src/server/selectors/selector';
import SpecialSaleDefaultImage from 'src/assets/images/specialSale/defaultBannerImage.png';
import { fetchSpecialSales, fetchAllOfferProduct } from 'src/server/slice/specialSaleSlice';

// ...
import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import OspLink from 'src/components/link/osp-link';
import { useSettingsContext } from 'src/components/settings';

import ProductCardOne from 'src/sections/product/cards/product-card-one';

import { SpecialSaleItemSkeleton } from './special-sale-skeleton';
import SpecialSaleProductComp from './special-sale-item-products';

export default function SpecialSaleListView() {
  const settings = useSettingsContext();
  const dispatch = useDispatch();
  const getText = useLocalizedText();

  const mdDown = useResponsive('down', 'md');
  const { isMobile, screenWidth } = useScreenWidth();
  const rtl = useLayoutStyle();

  const { specialSales, loading, products } = useSelector(getSpecialSaleState);
  useEffect(() => {
    const credentials = {
      page: 1,
      search: '',
      limit: specialSales?.total || 20,
      state: {
        filter_data: {
          is_deleted: false,
          is_active: true,
        },
        sort_data: {
          priority: 1,
        },
      },
    };
    dispatch(fetchSpecialSales(credentials));
  }, [specialSales?.total, dispatch]);
  useEffect(() => {
    const credentials = {
      page: 1,
      search: '',
      limit: products?.total || 20,
      state: {
        filter_data: {
          is_deleted: false,
          is_active: true,
        },
        sort_data: {
          priority: 1,
        },
      },
    };
    dispatch(fetchAllOfferProduct(credentials));
  }, [products?.total, dispatch]);
  const SpecialSaleData = specialSales?.specialSales;
  // console.log('SpecialSaleData-------------->>', SpecialSaleData);

  const renderSkeleton = (
    <Grid container spacing={2} sx={{ p: 2 }}>
      {[...Array(16)].map((_, index) => (
        <Grid item key={index} xs={4} sm={3} md={3} lg={1.5}>
          <SpecialSaleItemSkeleton />
        </Grid>
      ))}
    </Grid>
  );

  const renderList = (
    <Container
      maxWidth={settings.themeStretch ? false : 'xl'}
      sx={{
        mb: 15,
        my: isMobile ? 2 : 4,
      }}
    >
      <Stack>

        {SpecialSaleData?.length > 0 ? (
          SpecialSaleData?.map((item, key) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const translatedTitle = useTranslatedText(item?.title);

            return (
              <Stack mb={5}>
                <OspLink href="" sx={{ '&:hover': { textDecoration: 'none' } }}>
                  <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
                    <Box position="relative">
                      <Typography variant="body1" fontSize={isMobile ? 18 : 20} color="black">
                        {translatedTitle}
                      </Typography>
                      <Box
                        sx={{
                          backgroundColor: colors.themeSecondary,
                          height: 2,
                          width: 150,
                          position: 'absolute',
                          bottom: -9,
                        }}
                      />
                    </Box>

                    {/* {groupDetail?.link && ( */}
                    <Typography
                      variant="body2"
                      display="flex"
                      alignItems="center"
                      gap={2}
                      color="text.disabled"
                    >
                      {getText('view_all')}
                      <IconButton
                        sx={{
                          backgroundColor: colors.themePrimary,
                          borderRadius: '50%',
                          p: isMobile ? '5px' : 0.5,
                          '&:hover': {
                            backgroundColor: colors.themePrimary,
                          },
                        }}
                      >
                        <Iconify
                          icon={`solar:alt-arrow-${rtl ? 'left' : 'right'}-outline`}
                          width={isMobile ? 12 : 20}
                          color="white"
                        />
                      </IconButton>
                    </Typography>
                    {/* )} */}
                  </Stack>
                  <Divider sx={{ mb: 2, mt: 1 }} />
                </OspLink>
                <ImageComp item={item} isMobile={isMobile} />
                <SpecialSaleProductComp products={item} loading={loading} />

              </Stack>
            );
          })
        ) : (
          <Grid container item spacing={1}>
            {products?.offer_products?.map((product, key) => (
              <Grid item xs={6} lg={2} key={key}>
                <ProductCardOne product={product} />
              </Grid>
            ))}
          </Grid>
        )}
      </Stack>
    </Container>
  );

  const getMarginTop = () => {
    if (isMobile) return 0;
    if (screenWidth < 900) return 18;
    if (screenWidth < 1200) return 8;
    return 0;
  };

  return (
    <>
      <Box mt={getMarginTop()} className={` ${mdDown ? 'mt-55' : ''}`}>
        <Image
          src={SpecialSaleDefaultImage}
          // alt={BrandAltText}
          alt={getText('special_sale')}
          style={{
            objectFit: 'contain',
            maxWidth: '100%',
            aspectRatio: 1 / 1,
          }}
        />{' '}
      </Box>

      {loading ? renderSkeleton : renderList}
    </>
  );
}

const ImageComp = ({ item, isMobile }) => {
  const transformedImage = useImageTransformation(item?.banner_image?.url, 'w_1600', true);

  return (
    <>
      <Helmet>
        <link rel="preload" as="image" href={transformedImage} />
      </Helmet>
      <Image
        alt="banner"
        src={transformedImage}
        sx={{}}
        loading="eager"
        imageStyle={{
          height: isMobile ? 'unset' : '550px',
          objectFit: 'cover',
          borderRadius: !isMobile && '15px',
          aspectRatio: isMobile ? 'unset' : '16/9',
        }}
        // priority
      />
    </>
  );
};

ImageComp.propTypes = {
  item: PropTypes.any,
  isMobile: PropTypes.bool,
};
// const SpecialSaleProductComp = ({ item }) => 
//   item?.items?.length ? (
//     <Grid container  mt={2}spacing={1}>
//       {item.items.map((product, key) => (
//         <Grid item xs={6} lg={2} key={key}>
//           <ProductCardOne product={product} />
//         </Grid>
//       ))}
//     </Grid>
//   ) : null;


// SpecialSaleProductComp.propTypes = {
//   item: PropTypes.any,
// };
