// store/bannerSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { useGetToken } from 'src/hooks/useHandleSessions';

import { covertToJSON } from 'src/utils/stateToJson';
import { saveToStorage, getFromStorage } from 'src/utils/cache-storage';

// eslint-disable-next-line import/no-cycle
import { logOutUser } from './authentication';
import { get, put, del, post, TOKEN_PREFIX } from '../http';

// Thunk to fetch user address
export const fetchAllAddress = createAsyncThunk(
  'profile/shipping-address',
  async ({ page, limit, search }, { rejectWithValue }) => {
    try {
      const response = await post(
        `/user-shipping-address/user/all?page=${page}&limit=${limit}&search=${search}`
      );
      if (response.status === 2000) {
        return response.data;
      }
      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);

// Thunk to create user address
export const createNewAddress = createAsyncThunk(
  'profile/create-address',
  async ({ state, onClose, enqueueSnackbar, dispatch, createOrder }, { rejectWithValue }) => {
    try {
      const response = await post(`/user-shipping-address/user/new`, state);

      if (response.status === 2000) {
        const credentials = {
          state: {
            filter_data: {
              is_deleted: false,
              is_active: true,
            },
          },
          page: 1,
          search: '',
          limit: 10,
        };
        dispatch(fetchAllAddress(credentials));

        if (onClose) {
          onClose();
        }
        enqueueSnackbar('Address Created Successfully', {
          variant: 'success',
        });
        return { ...response.data, createOrder };
      }

      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);
// Thunk to get single user address
export const fetchAddressDetails = createAsyncThunk(
  'profile/details-address',
  async ({ addressId }, { rejectWithValue }) => {
    try {
      const response = await get(`/user-shipping-address/user/single/${addressId}`);
      if (response.status === 2000) {
        return response.data;
      }
      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);
// Thunk to update user address
export const updateUserAddress = createAsyncThunk(
  'profile/update-address',
  async ({ state, addressId, onClose, enqueueSnackbar, dispatch }, { rejectWithValue }) => {
    try {
      const response = await put(`/user-shipping-address/user/${addressId}`, state);
      if (response.status === 2000) {
        const credentials = {
          state: {
            filter_data: {
              is_deleted: false,
              is_active: true,
            },
          },
          page: 1,
          search: '',
          limit: 10,
        };
        dispatch(fetchAllAddress(credentials));

        if (onClose) {
          onClose();
        }
        enqueueSnackbar('Address Updated Successfully', {
          variant: 'success',
        });
        return response.data;
      }
      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);
// Thunk to update default address
export const setDefaultAddress = createAsyncThunk(
  'profile/default-address',
  async ({ state }, { rejectWithValue }) => {
    try {
      const response = await post(`/user-shipping-address/user/update/default`, state);
      if (response.status === 2000) {
        return response.data;
      }
      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);
// Thunk to remove address
export const removeAddress = createAsyncThunk(
  'profile/remove-address',
  async ({ addressId, onClosePopup, enqueueSnackbar }, { rejectWithValue }) => {
    try {
      const response = await del(`/user-shipping-address/user/hard-delete/${addressId}`);
      if (response.status === 2000) {
        if (onClosePopup) {
          onClosePopup();
        }
        enqueueSnackbar('Address Deleted Successfully', {
          variant: 'success',
        });
        return response.data;
      }
      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);
export const makeDefaultAddress = createAsyncThunk(
  'address/default/update',
  async ({ state, enqueueSnackbar, dispatch }, { rejectWithValue }) => {
    try {
      const response = await post(`/user-shipping-address/user/update/default`, state);
      if (response.status === 2000) {
        enqueueSnackbar('Address has been successfully set as the default.', {
          variant: 'success',
        });
        dispatch(fetchAllAddress({ page: 1, limit: 10, search: '' }));

        return response.data;
      }

      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);

export const fetchUser = createAsyncThunk('user/me', async (_, { rejectWithValue }) => {
  const { isSuccess, token } = useGetToken(TOKEN_PREFIX);
  try {
    if (isSuccess && token) {
      const cachedData = getFromStorage('loginData');

      if (cachedData?.timestamp) {
        return cachedData;
      }

      const response = await get(`/user/me`);
      if (response.status === 2000) {
        saveToStorage('loginData', response.data);
        return response.data;
      }

      return rejectWithValue(response.message || 'Something went wrong');
    }
    return '';
  } catch (error) {
    return rejectWithValue(error.response?.data?.message || 'Network Error');
  }
});

export const updateUser = createAsyncThunk(
  'user/update',
  async ({ state, enqueueSnackbar, dispatch, handleClose, handleClear }, { rejectWithValue }) => {
    try {
      const response = await put(`/user/update`, state);
      if (response.status === 2000) {
        if (dispatch) {
          dispatch(fetchUser());
        }
        enqueueSnackbar('Profile Updated Successfully', {
          variant: 'success',
        });
        if (handleClose) {
          handleClose();
        }
        if (handleClear) {
          handleClear();
        }
        saveToStorage('loginData', response.data?.data);
        return response.data;
      }

      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);
export const fetchWishList = createAsyncThunk(
  'wishlist/me',
  async ({ page, search, limit }, { rejectWithValue }) => {
    const { isSuccess, token } = useGetToken(TOKEN_PREFIX);
    try {
      if (isSuccess && token) {
        const response = await post(
          `/wishlist-item/user/all?page=${page || 1}&search=${search || ''}&limit=${limit || 5}`
        );
        if (response.status === 2000) {
          return response.data;
        }
        return rejectWithValue(response.message || 'Something went wrong');
      }
      return '';
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);
export const fetchWishListUpdate = createAsyncThunk(
  'wishlist/update',
  async ({ state, enqueueSnackbar, dispatch, handleRemove }, { rejectWithValue }) => {
    try {
      const response = await post(`/wishlist-item/user/update`, state);
      if (response.status === 2000) {
        enqueueSnackbar('Wishlist updated', {
          variant: 'success',
        });

        if (dispatch) {
          dispatch(fetchIsWishlistCheck({ productId: response?.data?.product }));
        }

        if (handleRemove) {
          handleRemove();
        }
        return response?.data?.uid;
      }
      return enqueueSnackbar(response.message, {
        variant: 'error',
      });

      // const { isSuccess, token } = useGetToken(TOKEN_PREFIX);
      // if (isSuccess && token) {
      // return rejectWithValue(response.message || 'Something went wrong');
      // }
      // return enqueueSnackbar(
      //   state?.operation === 'ADD' ? 'Added to wishlist' : 'Removed from wishlist',
      //   {
      //     variant: 'success',
      //   }
      // );;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);
export const fetchIsWishlistCheck = createAsyncThunk(
  'wishlist/check',
  async ({ productId }, { rejectWithValue }) => {
    const { isSuccess, token } = useGetToken(TOKEN_PREFIX);
    try {
      if (isSuccess && token) {
        const response = await get(`/wishlist-item/user/check/product/${productId}`);
        if (response.status === 2000) {
          return response?.data;
        }
        return rejectWithValue(response.message || 'Something went wrong');
      }
      return '';
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);
const userSlice = createSlice({
  name: 'profile',
  initialState: {
    loading: false,
    createAddressLoading: false,
    error: null,
    profile: {},
    address: [],
    logoutLoading: false,
    createdNewAddress: false,
    cart: [],
    user: {},
    addressDetail: {},
    wishListItems: {},
    isWishList: false,
  },
  reducers: {
    clearUserState: (state) => {
      state.profile = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAddress.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllAddress.fulfilled, (state, action) => {
        state.loading = false;
        state.address = action.payload;
        state.error = null;
      })
      .addCase(fetchAllAddress.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(createNewAddress.pending, (state) => {
        state.createAddressLoading = true;
      })
      .addCase(createNewAddress.fulfilled, (state, action) => {
        const jsonState = covertToJSON(state)?.address || [];
        const userShippingAddresses = jsonState?.userShippingAddresses || [];

        state.address = {
          ...state.address,
          userShippingAddresses: [action.payload, ...userShippingAddresses],
        };
        state.addressDetail = action.payload;
        state.createdNewAddress = action.payload.createOrder;
        state.createAddressLoading = false;
        state.error = null;
      })
      .addCase(createNewAddress.rejected, (state, action) => {
        state.createAddressLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(updateUserAddress.pending, (state) => {
        state.createAddressLoading = true;
      })
      .addCase(updateUserAddress.fulfilled, (state, action) => {
        const jsonState = covertToJSON(state)?.address || [];
        const userShippingAddresses = jsonState?.userShippingAddresses || [];

        state.address = {
          ...state.address,
          userShippingAddresses: userShippingAddresses?.map((item) =>
            item?.uid === action.payload?.uid ? action.payload : item
          ),
        };
        state.createAddressLoading = false;
        state.error = null;
      })
      .addCase(updateUserAddress.rejected, (state, action) => {
        state.createAddressLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(fetchAddressDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAddressDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.addressDetail = action.payload;
        state.error = null;
      })
      .addCase(fetchAddressDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(setDefaultAddress.pending, (state) => {
        state.loading = true;
      })
      .addCase(setDefaultAddress.fulfilled, (state, action) => {
        state.loading = false;
        state.address = action.payload;
        state.error = null;
      })
      .addCase(setDefaultAddress.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(removeAddress.pending, (state) => {
        state.createAddressLoading = true;
      })
      .addCase(removeAddress.fulfilled, (state, action) => {
        const jsonState = covertToJSON(state)?.address || [];
        const userShippingAddresses = jsonState?.userShippingAddresses || [];

        state.address = {
          ...state.address,
          userShippingAddresses: userShippingAddresses?.filter(
            (item) => item?.uid !== action.payload?.uid
          ),
        };
        state.createAddressLoading = false;
        state.error = null;
      })
      .addCase(removeAddress.rejected, (state, action) => {
        state.createAddressLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(fetchUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.error = null;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.error = null;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(logOutUser.pending, (state) => {
        state.logoutLoading = true;
      })
      .addCase(logOutUser.fulfilled, (state, action) => {
        state.logoutLoading = false;
        state.user = {};
        state.error = null;
      })
      .addCase(logOutUser.rejected, (state, action) => {
        state.logoutLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(fetchWishList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchWishList.fulfilled, (state, action) => {
        state.loading = false;
        state.wishListItems = action.payload;
        state.error = null;
      })
      .addCase(fetchWishList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(fetchWishListUpdate.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchWishListUpdate.fulfilled, (state, action) => {
        const jsonState = covertToJSON(state)?.wishListItems;
        const modifiedList = {
          ...jsonState,
          wishlist_items: jsonState.wishlist_items?.filter(
            (wishList) => wishList.uid !== action.payload
          ),
        };
        state.loading = false;
        state.wishListItems = modifiedList;
        state.error = null;
      })
      .addCase(fetchWishListUpdate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(fetchIsWishlistCheck.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchIsWishlistCheck.fulfilled, (state, action) => {
        state.loading = false;
        state.isWishList = action.payload;
        state.error = null;
      })
      .addCase(fetchIsWishlistCheck.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { clearUserState } = userSlice.actions;

export default userSlice.reducer;
