import axiosApi from 'axios';

import { useGetToken } from '../hooks/useHandleSessions';

// apply base url for axios
export const TOKEN_PREFIX = 'token';
export const RESOURCE_BASE_URL = 'https://res.buildex.osperb.com';

export const frontEndUrl = 'https://buildexstores.com';
export const timeZone = 'Asia/Muscat';

export const env = 'dev';

export const DEV_BASE_URL = 'https://booking.buildex.osperb.com';
export const PRO_BASE_URL = 'https://booking.buildexstores.com';
export const ONE_HOUR = 60 * 60 * 1000; // one hour

const axios = axiosApi.create({
  baseURL: env === 'dev' ? DEV_BASE_URL : PRO_BASE_URL,
});

axios.interceptors.request.use(
  (config) => {
    const { token } = useGetToken(TOKEN_PREFIX);
    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => error
);

axios.interceptors.response.use(
  (response) => response,
  (err) => {
    console.error(err.response.status);
    return Promise.reject(err);
  }
);

export async function get(url, config = {}) {
  return axios.get(url, { ...config }).then((response) => response.data);
}

export async function post(url, data, config = {}) {
  return axios.post(url, { ...data }, { ...config }).then((response) => response.data);
}

export async function put(url, data, config = {}) {
  return axios.put(url, { ...data }, { ...config }).then((response) => response.data);
}

export async function patch(url, data, config = {}) {
  return axios.patch(url, { ...data }, { ...config }).then((response) => response.data);
}

export async function del(url, config = {}) {
  return axios.delete(url, { ...config }).then((response) => response.data);
}

// axios.interceptors.request.use(
//   (config) => {
//     const { token } = useGetToken(TOKEN_PREFIX);
//     const { role } = useGetRoles();

//     if (token) {
//       config.headers.authorization = `Bearer ${token}`;
//     }
//     const handleDashboardApp = () => {
//       switch (role) {
//         case 'super_admin':
//           return 'admin';
//         case 'partner':
//           return 'partner';
//         case 'school_user':
//           return 'school';
//         default:
//           return '';
//       }
//     };

//     if (config && config.url && config.url.includes("/admin")) {
//       const parts = config.url.split("/admin")
//       config.url = ${parts[0]}/${handleDashboardApp()}${parts[1]}
//     }
//     return config;
//   },

//   (error) => error
// );
// if (err.response.status === 4001) {
//   window.location.replace(paths.public.home);
//   return Promise.reject(err);
// }
