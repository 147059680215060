const ROOTS = {
  MY_ACCOUNT: 'my-account',
  Legal: 'legal',
};

export const paths = {
  // static
  contactUs: '/contact-us',
  faq: '/faq',
  legal: {
    returnPolicy: `/return-policy`,
    termsAndConditions: `/terms-and-conditions`,
    privacyPolicy: `/privacy-policy`,
    shippingPolicy: `/shipping-policy`,
    faq: `/faq`,
  },

  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',

  page403: '/error/403',
  page404: '/error/404',
  page500: '/error/500',
  offline: '/error/offline',

  public: {
    home: '/',
    login: '/login',
    products: '/products',
    productView: (slug) => `/product/${slug}`,

    categoryView: (slug) => `/category/${slug?.toLowerCase()}`,

    brands: '/brands',
    brandView: (slug) => `/brand/${slug?.toLowerCase()}`,

    collections: '/collections',
    collectionView: (slug) => `/collection/${slug?.toLowerCase()}`,

    pages: '/pages',
    pageView: (slug) => `/page/${slug}`,

    cart: '/cart',
    wishlist: '/wishlist',
    blogs: '/blogs',
    blogView: (slug) => `/blogs/${slug}`,

    search: '/search',
    searchResults: (query) => `/search?query=${query}`,
    reviews: '/reviews',
    review_view: (slug) => `/reviews/${slug}`,
  },

  private: {
    order_success: (slug) => `/order-success/${slug}`,
    order_redirect: `/order-redirect`,
    order_fail: `/order-fail`,
    checkout: `/checkout`,
    my_account: {
      root: `/${ROOTS.MY_ACCOUNT}`,
      orderView: (slug) => `/${ROOTS.MY_ACCOUNT}/orders/${slug}`,
    },
  },
};
