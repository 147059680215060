import React from 'react';
import PropTypes from 'prop-types';

import { Stack } from '@mui/system';
import { Grid } from '@mui/material';

import ProductCardOne from 'src/sections/product/cards/product-card-one';
import { MyProfileReviewSkeleton } from 'src/sections/myAccount/review/review-skeleton';

// ----------------------------------------------------------------------

export default function SpecialSaleProductComp({ products, loading }) {
  // pagination taken from review my account section so refer that

  return (
    <>
      {loading ? (
        <Grid item xs={12} sm={12} md={12} sx={{ display: 'flex', flexDirection: 'column' }}>
          <MyProfileReviewSkeleton />
        </Grid>
      ) : (
        <Stack spacing={1} mb={4}>
          <Grid container mt={2} spacing={1}>
            {(products?.items || []).map((product) =>
              product ? (
                <Grid item xs={6} lg={2} key={product.id || product.name}>
                  <ProductCardOne product={product} />
                </Grid>
              ) : null
            )}
          </Grid>
          {/* {totalReview > 6 && (
            <Pagination
              onChange={(e, newPage) => setCurrentPage(newPage)}
              count={totalPages || 8}
              page={currentPage}
              sx={{
                my: 4,
                [`& .${paginationClasses.ul}`]: {
                  justifyContent: 'center',
                },
              }}
            />
          )} */}
        </Stack>
      )}
    </>
  );
}

SpecialSaleProductComp.propTypes = {
  products: PropTypes.any,
  loading: PropTypes.any,
};
