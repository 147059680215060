import { usePathname } from 'src/routes/hooks';

import MetaHead from 'src/utils/metaHead';

import { frontEndUrl } from 'src/server/http';

import SpecialSaleListView from 'src/sections/specialSale/specialSaleList/special-sale-list';

export default function SpecialSalePage() {
  const path = usePathname();
  const canonicalUrl = frontEndUrl + path;

  return (
    <>
      <MetaHead
        canonical={canonicalUrl}
        title="Shop Top Brands"
        description="Browse and shop a wide variety of top brands available on Buildex. Get the best deals on quality products!"
        keywords=""
        openGraph={{
          title: 'Shop Top Brands',
          description: 'Browse top brands and find the best deals on quality products at Buildex.',
          image: {
            url: '/assets/images/logs/favIcon.png',
          },
        }}
        twitter={{
          title: 'Shop Top Brands',
          description: 'Browse top brands and find the best deals on quality products at Buildex.',
          image: {
            url: '/assets/images/logs/favIcon.png',
          },
        }}
      />
      <SpecialSaleListView />
    </>
  );
}
