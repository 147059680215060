import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { saveToStorage, getFromStorage } from 'src/utils/cache-storage';

import { get, post } from '../http';

// Thunk to fetchSeo
export const fetchSEO = createAsyncThunk(
  'product/fetchSeo',
  async ({ state }, { rejectWithValue }) => {
    try {
      const response = await post(`/seo-item/user/module/single/name`, state);
      if (response.status === 2000) {
        return response.data;
      }
      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);

// Thunk to fetchCurrency
export const fetchCurrency = createAsyncThunk(
  'product/fetchCurrency',
  async ({ page, search, limit, state }, { rejectWithValue }) => {
    try {
      const cachedData = getFromStorage('currency');

      if (cachedData?.timestamp) {
        return cachedData;
      }

      const response = await post(
        `/currency/user/all?page=${page}&search=${search}&limit=${limit}`,
        state
      );
      if (response.status === 2000) {
        saveToStorage('currency', response.data);
        return response.data;
      }
      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);

// Thunk to fetchPickupLocation
export const fetchPickupLocation = createAsyncThunk(
  'pickupLocation/all',
  async ({ page, search, limit, state }, { rejectWithValue }) => {
    try {
      const response = await post(
        `/pickup-location/user/all?page=${page}&search=${search}&limit=${limit}`,
        state
      );
      if (response.status === 2000) {
        return response.data;
      }
      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);

// Thunk to fetchPickupLocation
// type : /all /basic /shipping /payment /legal

export const fetchSettings = createAsyncThunk(
  'settings/all',
  async ({ type }, { rejectWithValue }) => {
    try {
      const cachedData = getFromStorage(type);

      if (!cachedData?.timestamp) {
        const response = await get(`/settings/user/${type}`);
        if (response.status === 2000) {
          const finalData = { ...response.data, type };
          saveToStorage(type, finalData);
          return finalData;
        }
        return rejectWithValue(response.message || 'Something went wrong');
      }

      if (cachedData?.timestamp) {
        return cachedData;
      }
      return '';
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);

// Thunk to fetchStates
export const fetchStates = createAsyncThunk(
  'states/all',
  async ({ page, search, limit, state }, { rejectWithValue }) => {
    try {
      const response = await post(
        `/state/user/all?page=${page}&search=${search}&limit=${limit}`,
        state
      );
      if (response.status === 2000) {
        return response.data;
      }
      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);
// Thunk to fetch country
export const fetchCountry = createAsyncThunk(
  'country/all',
  async ({ page, search, limit, state }, { rejectWithValue }) => {
    try {
      const response = await post(
        `/country/user/all?page=${page}&search=${search}&limit=${limit}`,
        state
      );
      if (response.status === 2000) {
        return response.data;
      }
      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);
// Thunk to fetch District
export const fetchDistrict = createAsyncThunk(
  'district/all',
  async ({ page, search, limit, state }, { rejectWithValue }) => {
    try {
      const response = await post(
        `/district/user/all?page=${page}&search=${search}&limit=${limit}`,
        state
      );
      if (response.status === 2000) {
        return response.data;
      }
      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);

const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    loading: false,
    stateLoading: false,
    error: null,
    currency: {},
    SEOData: {},
    pickupLocations: {},
    accountTab: 'orders',
    states: {},
    country: {},
    district: {},
    basicSettings: {},
    paymentSettings: {},
    legalSettings: {},
    shippingSettings: {},
    cache: {},
  },
  reducers: {
    clearProductState: (state) => {
      state.currency = [];
    },
    updateAccountTab: (state, action) => {
      state.accountTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrency.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCurrency.fulfilled, (state, action) => {
        state.loading = false;
        state.currency = action.payload;
        state.error = null;
      })
      .addCase(fetchCurrency.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(fetchSEO.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSEO.fulfilled, (state, action) => {
        state.loading = false;
        state.SEOData = action.payload;
        state.error = null;
      })
      .addCase(fetchSEO.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(fetchPickupLocation.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPickupLocation.fulfilled, (state, action) => {
        state.loading = false;
        state.pickupLocations = action.payload;
        state.error = null;
      })
      .addCase(fetchPickupLocation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(fetchSettings.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSettings.fulfilled, (state, action) => {
        state.loading = false;
        switch (action?.payload?.type) {
          case 'basic':
            state.basicSettings = action.payload;
            break;
          case 'payment':
            state.paymentSettings = action.payload;
            break;
          case 'legal':
            state.legalSettings = action.payload;
            break;
          case 'shipping':
            state.shippingSettings = action.payload;
            break;

          default:
            break;
        }
        state.error = null;
      })
      .addCase(fetchSettings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(fetchStates.pending, (state) => {
        state.stateLoading = true;
      })
      .addCase(fetchStates.fulfilled, (state, action) => {
        state.stateLoading = false;
        state.states = action.payload;
        state.error = null;
      })
      .addCase(fetchStates.rejected, (state, action) => {
        state.stateLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(fetchDistrict.pending, (state) => {
        state.stateLoading = true;
      })
      .addCase(fetchDistrict.fulfilled, (state, action) => {
        state.stateLoading = false;
        state.district = action.payload;
        state.error = null;
      })
      .addCase(fetchDistrict.rejected, (state, action) => {
        state.stateLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(fetchCountry.pending, (state) => {
        state.stateLoading = true;
      })
      .addCase(fetchCountry.fulfilled, (state, action) => {
        state.stateLoading = false;
        state.country = action.payload;
        state.error = null;
      })
      .addCase(fetchCountry.rejected, (state, action) => {
        state.stateLoading = false;
        state.error = action.error.message;
      });
  },
});

export const { clearProductState, updateAccountTab } = settingsSlice.actions;

export default settingsSlice.reducer;
