// store/specialSaleSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { saveToStorage, getFromStorage } from 'src/utils/cache-storage';

import { get, post } from '../http';

// Thunk to fetch specialSales
export const fetchSpecialSales = createAsyncThunk(
  'specialSale/fetchSpecialSales',
  async ({ page, search, limit, state }, { rejectWithValue }) => {
    try {
      const response = await post(
        `/special-sale/user/current/all?page=${page}&search=${search}&limit=${limit}`,
        state
      );
      if (response.status === 2000) {
        return response.data;
      }
      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);
export const fetchSpecialSaleSingleView = createAsyncThunk(
  'specialSale/fetchSpecialSaleSingleView',
  async ({ name }, { rejectWithValue }) => {
    try {
      const cachedData = getFromStorage(name);

      if (cachedData?.timestamp) {
        return cachedData;
      }
      const response = await get(`/special-sale/user/single/${name}`);
      if (response.status === 2000) {
        saveToStorage(name, response.data);
        return response.data;
      }
      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);
export const fetchAllOfferProduct = createAsyncThunk(
  'specialSale/fetchOfferProduct',
  async ({ page, search, limit, state }, { rejectWithValue }) => {
    try {
      const response = await post(
        `/product/user/offer/all?page=${page}&search=${search}&limit=${limit}`,
        state
      );
      if (response.status === 2000) {
        return response.data;
      }
      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);
const specialSaleSlice = createSlice({
  name: 'specialSale',
  initialState: {
    loading: false,
    error: null,
    specialSales: [],
    products:[],
    specialSaleSingleView: {},
    specialSaleSingleViewList:[]
  },
  reducers: {
    clearSpecialSaleState: (state) => {
      state.specialSales = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSpecialSales.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSpecialSales.fulfilled, (state, action) => {
        state.loading = false;
        state.specialSales = action.payload;
        state.error = null;
      })
      .addCase(fetchSpecialSales.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
      builder
      .addCase(fetchAllOfferProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllOfferProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.products = action.payload;
        state.error = null;
      })
      .addCase(fetchAllOfferProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(fetchSpecialSaleSingleView.pending, (state) => {
        state.loading = true;
      })
      // .addCase(fetchSpecialSaleSingleView.fulfilled, (state, action) => {
      //   state.loading = false;
      //   // state.specialSaleSingleView = action.payload;
        

      //   state.error = null;
      // })
      .addCase(fetchSpecialSaleSingleView.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
    
        const existingIndex = state.specialSaleSingleViewList.findIndex(
            (item) => item._id === action.payload._id
        );
    
        if (existingIndex !== -1) {
            // Replace existing object
            state.specialSaleSingleViewList[existingIndex] = action.payload;
        } else {
            // Add new object
            state.specialSaleSingleViewList.push(action.payload);
        }
    })
    
      .addCase(fetchSpecialSaleSingleView.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  
  },
});

export const { clearSpecialSaleState } = specialSaleSlice.actions;

export default specialSaleSlice.reducer;
